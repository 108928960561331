/* 
.flipX {
  animation: 1s anim-lineUp ease-in-out;
}

@keyframes anim-lineUp {
  0% {
    opacity: 0;
    transform: translateY(80%);
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
} */


.cube{
  -webkit-transition: -webkit-transform .3s;
  transition: transform .3s;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d
}
.flipNormal{
  /* animation: anim-rotate-normal 1s forwards; */
}
.flip{
  animation: anim-rotate 0.5s forwards;
}


@keyframes anim-rotate-normal {
  0% {
    transform: rotateX(180deg);
  }
  100% {
    transform: rotateX(360deg);

  }
}


@keyframes anim-rotate {
  0% {
    transform: rotateX(-90deg) scale(1.8);
  }
  50% {
    transform: rotateX(45deg) scale(1.4);
  }
  100% {
    transform: scale(1.8);
  }
}


