
.textContainer{
    min-height: 100px;
    position: relative;
    left: 65px;
    top: 20%;
}
.title{
    position: relative;
    -webkit-text-stroke: 1px white;
    -webkit-text-fill-color: transparent;
    font-size: 14vw;
    font-weight: bold;
    font-family:Tahoma, sans-serif;
    opacity: 0;
    animation: slide-up 1s forwards;
    animation-delay: 0.8s;
}
.subTitle{
    position: absolute;
    top:70%;
    font-size: 6vw;
    font-weight: bold;
    color: #ffffff;
    font-family:Tahoma, sans-serif;
    opacity: 0;
    animation: slide-up-sub 1s forwards;
    animation-delay: 1s;
}
.movingContainer{
    overflow-x: hidden;
    min-height: 20vh;
    position: relative;
}

.about_us_paragraph{
    position: absolute;
    width: 50%;
    font-family: 'Inter';
    /* font-style: normal; */
    font-weight: 300;
    font-size: 1.3vw;
    /* line-height: 165.52%; */
    letter-spacing: 0.055em;
    margin-left: 40px;
    margin-top: -50px;
    opacity: 0;
    animation: slide-up-about 1s forwards;
    animation-delay: 0.6s;
}
.rotated_title{
    -webkit-text-stroke: 1px black;
    -webkit-text-fill-color: transparent;
    font-size: 9vw;
    font-weight: bold;
    font-family:Tahoma, sans-serif;
    text-transform: uppercase;
    transform: rotate(270deg);
    transform-origin: 75%;
    position: absolute;
    top: 10%;
    left:-50%;
}
.absolute_title{
    font-size: 14vw;
    font-weight: bold;
    font-family:Tahoma, sans-serif;
    text-transform: uppercase;
    color:#017EFF ;
    position: absolute;
    top:0%;
    left:17%;
}
.footer{
    min-height: 50vh;
    background-color:#000E3E;
}
.footer_title{
    font-size: 5vh;
    text-transform:uppercase;
    font-weight: 500;
    letter-spacing: 0.055em;
    background: linear-gradient(88.35deg, #007EFF -13.53%, #40FCFF 112.33%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    /* -webkit-text-fill-color: transparent; */
}
.footer_menu{
    text-transform: uppercase;
}
.footer_menu_item{
    display: flex;
    color: #ffffff;
    align-items: center;
    text-align: left;
    cursor: pointer;
}
.footer_logo{
    padding-bottom:20px;
}

@media only screen and (max-width: 1200px) and (min-width:1001px) {
    .subTitle{
        top: 40%;
    }
}

@media only screen and (max-width: 1000px) {
    .subTitle{
        top: 26%;
    }
}
@media only screen and (max-width: 700px) {
    .subTitle{
        top: 22%;
    }
}

@keyframes an{
    from{
        opacity: 0;
        transform: perspective(500px) translate3d(-35px, -40px, -150px) rotate3d(1, -1, 0, 35deg);
    }
    to{
        opacity: 1;
        transform: perspective(500px) translate3d(0, 0, 0);
    }
}

@keyframes slide-up {
    0% {
      top: 100%;
      opacity: 0;
    }
    100% {
      top: 10%;
      opacity: 1;
    }
}
@keyframes slide-up-sub {
    0% {
      top: 100%;
      opacity: 0;
    }
    100% {
      top: 70%;
      opacity: 1;
    }
}

@keyframes slide-up-about {
    0% {
      top: 100%;
      opacity: 0;
    }
    100% {
      top: 70%;
      opacity: 1;
    }
}
